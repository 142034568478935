const defaults = {
  productName: 'Excelkits',
  productNameSpace: 'exk',
  productUrl: process.env.NEXT_PUBLIC_BASE_URL,
  externalEmail: 'matt@industriousapps.com',
  description: 'Turn your Excel into lead magnets, calculators, quizzes, sales tools, products and more',
  companyName: 'Industrious Apps, LLC',
  mail: {
    streetAddress: '750 N Saint Paul St Ste 250',
    city: 'Dallas',
    state: 'Texas',
    zip: '75201-3206',
    country: 'USA'
  },
  legalLinks: [{
    name: 'About',
    // TODO move
    url: '/about'
  }, {
    name: 'Contact',
    // TODO move
    url: '/contact'
  }, {
    name: 'Privacy Policy',
    url: '/privacy-policy'
  }, {
    name: 'Terms & Conditions',
    url: '/terms-and-conditions'
  }],
  demoLinks: {},
  // TODO: migrate to config/design
  colors: {
    bluePrimary: '#004de5',
    bodyFontColor: '#12131a',
    gray60: '#696C80',
    gray100: '#12131a'
  }
};
export default defaults; // Used by some form elements
// that require custom validation

export const customFormValidityMethod = `${defaults.productNameSpace}CheckValidity`;