const BASE_URL = `${process.env.NEXT_PUBLIC_BASE_URL || ''}`.replace(/\/$/, '');
export const LOG_LEVEL = process.env.NEXT_PUBLIC_LOG_LEVEL || 'warn';
export default {
  baseUrl: BASE_URL,
  logLevel: LOG_LEVEL,
  ignoreConsoleErrors: [/defaultProps/],
  ignoreConsoleWarnings: [/\[redi\]: Expect 0 custom parameter\(s\)/, /univer\.sheet\.cell-alert already exists/],
  ignoreUnhandledErrors: [/Cannot find "univer\.doc\.text-selection-render-manager"/, /React does not recognize the `fetchPriority` prop on a DOM element/],
  isDevelopment: false,
  isStaging: `${process.env.NEXT_PUBLIC_BASE_URL || ''}`.search('//staging.') > -1,
  isProduction: `${process.env.NEXT_PUBLIC_BASE_URL || ''}`.search('//excelkits.com') > -1,
  defaultSocialImg: `${BASE_URL}/img/social/og-image-default.jpg`
};