import { useFirestoreDocData } from 'reactfire';
import { auth, db } from '@/config/firebase';
import collections from '@/config/collections';
import { makeId } from 'utils/makeId';
const COLLECTION_NAME = collections.teams;
export const createTeam = data => {
  const {
    name
  } = data;
  const id = makeId(name);

  if (!auth.currentUser) {
    return Promise.reject(Error('User is not logged in'));
  }

  const ownerId = auth.currentUser.uid;
  const users = [{
    uid: auth.currentUser.uid,
    email: auth.currentUser.email,
    role: 'owner',
    status: 'active',
    createdAt: Date.now()
  }];
  return db.collection(COLLECTION_NAME).doc(id).set({
    id,
    name,
    ownerId,
    users
  }, {
    merge: true
  });
};
export const updateTeam = (id, data) => {
  return db.collection(COLLECTION_NAME).doc(id).update(data);
};
export const getTeam = teamId => {
  return db.collection(COLLECTION_NAME).doc(teamId).get().then(doc => doc.data());
};
export const getTeamName = teamId => {
  return db.collection(COLLECTION_NAME).doc(teamId).get().then(doc => {
    var _doc$data;

    return (_doc$data = doc.data()) === null || _doc$data === void 0 ? void 0 : _doc$data.name;
  });
}; /// Check if a team already has the given slug

export const isSlugAvailable = async slug => {
  const teams = await db.collection('teams').where('slug', '==', slug).get();
  return teams.empty;
};
export default {
  isSlugAvailable,
  getTeamName,
  getTeam,
  updateTeam,
  createTeam,

  // Create database document reference
  createDocRef(id) {
    return db.collection(COLLECTION_NAME).doc(id);
  },

  // Lookup a single record
  findRecord(fsDb, id) {
    const docRef = fsDb.collection(COLLECTION_NAME).doc(id); // eslint-disable-next-line

    const {
      status,
      error,
      data: srcData // eslint-disable-next-line

    } = useFirestoreDocData(docRef, {
      idField: 'id'
    }); // Cast data into local record

    const data = srcData; // Result

    return {
      status,
      error,
      data
    };
  }

};